import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import TopProgressBar from '../basics/TopProgressBar';

const AdminAuth = React.lazy(() => import('./admin/auth/AdminAuth'))
const ApplicationAdminAuth = React.lazy(() => import('./admin/auth/ApplicationAdminAuth'))
const FullAdminAuth = React.lazy(() => import('./admin/auth/FullAdminAuth'))
const UserAdminAuth = React.lazy(() => import('./admin/auth/UserAdminAuth'))
const Admin = React.lazy(() => import('../pages/admin/Admin'))
const AdminApplicationList = React.lazy(() => import('../pages/admin/AdminApplicationList'))
const AdminApplicationManage = React.lazy(() => import('../pages/admin/AdminApplicationManage'))
const AdminApplicationNew = React.lazy(() => import('../pages/admin/AdminApplicationNew'))
const AdminBlockList = React.lazy(() => import('../pages/admin/AdminBlockList'))
const AdminBlockManage = React.lazy(() => import('../pages/admin/AdminBlockManage'))
const AdminBlockNew = React.lazy(() => import('../pages/admin/AdminBlockNew'))
const AdminImportList = React.lazy(() => import('../pages/admin/AdminImportList'))
const AdminImportManage = React.lazy(() => import('../pages/admin/AdminImportManage'))
const AdminImportNew = React.lazy(() => import('../pages/admin/AdminImportNew'))
const AdminOAuthList = React.lazy(() => import('../pages/admin/AdminOAuthList'))
const AdminOAuthManage = React.lazy(() => import('../pages/admin/AdminOAuthManage'))
const AdminOAuthNew = React.lazy(() => import('../pages/admin/AdminOAuthNew'))
const AdminUnauthorized = React.lazy(() => import('../pages/admin/AdminUnauthorized'))
const AdminUserList = React.lazy(() => import('../pages/admin/AdminUserList'))
const AdminUserHighSecReport = React.lazy(() => import('../pages/admin/AdminUserHighSecReport'))
const AdminUserManage = React.lazy(() => import('../pages/admin/AdminUserManage'))
const ConfirmForgotPassword = React.lazy(() => import('../pages/ConfirmForgotPassword'))
const ForceChangePassword = React.lazy(() => import('../pages/ForceChangePassword'))
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'))
const ImportLanding = React.lazy(() => import('../pages/ImportLanding'))
const Logout = React.lazy(() => import('../pages/Logout'))
const MFAResponse = React.lazy(() => import('../pages/MFAResponse'))
const MyApps = React.lazy(() => import('../pages/MyApps'))
const SignIn = React.lazy(() => import('../pages/SignIn'))
const SignUp = React.lazy(() => import('../pages/SignUp'))
const UserAccountManagement = React.lazy(() => import('../pages/UserAccountManagement'))
const UserChangePassword = React.lazy(() => import('../pages/users/ChangePassword'))

export default function AppRoutes() {
    return (
        <Box sx={{position: 'absolute', minHeight: '100%', width: '100%'}}>
            <BrowserRouter>
                <Suspense fallback={<TopProgressBar />}>
                    <Routes>
                        <Route path="/" element={<SignIn />} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/importlanding" element={<ImportLanding />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path='/verifyemail' element={<SignUp />} />
                        <Route path='/verifyphonenumber' element={<SignUp />} />
                        <Route path="/mfaresponse" element={<MFAResponse />} />
                        <Route path='/manage' element={<UserAccountManagement />} />
                        <Route path="/myapps" element={<MyApps />} />
                        <Route path='/forgotpassword' element={<ForgotPassword eventMode='forgotPassword' />} />
                        <Route path='/resetpassword' element={<ForgotPassword eventMode='resetPassword' />} />
                        <Route path='/confirmforgotpassword' element={<ConfirmForgotPassword />} />
                        <Route path='/forcechangepassword' element={<ForceChangePassword />} />
                        <Route path='/profile/password' element={<UserChangePassword />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path="/admin" element={<AdminAuth />}>
                            <Route path="" element={<Admin />} />
                            <Route path='application' element={<ApplicationAdminAuth />}>
                                <Route path='manage' element={<AdminApplicationList />} />
                                <Route path='manage/:targetapplication' element={<AdminApplicationManage />} />
                                <Route path='new' element={<FullAdminAuth />}>
                                    <Route path='' element={<AdminApplicationNew />} />
                                </Route>
                            </Route>
                            <Route path='import' element={<FullAdminAuth />}>
                                <Route path='manage' element={<AdminImportList />} />
                                <Route path='manage/:targetimport' element={<AdminImportManage />} />
                                <Route path='new' element={<AdminImportNew />} />
                            </Route>
                            <Route path='oauth' element={<ApplicationAdminAuth />}>
                                <Route path='manage' element={<AdminOAuthList />} />
                                <Route path='manage/:targetclient' element={<AdminOAuthManage />} />
                                <Route path='new' element={<FullAdminAuth />}>
                                    <Route path='' element={<AdminOAuthNew />} />
                                </Route>
                            </Route>
                            <Route path='user' element={<UserAdminAuth />}>
                                <Route path='highsecurityreport' element={<AdminUserHighSecReport />} />
                                <Route path='manage' element={<AdminUserList />} />
                                <Route path='manage/:targetuser' element={<AdminUserManage />} />
                            </Route>
                            <Route path='block' element={<UserAdminAuth />}>
                                <Route path='manage' element={<AdminBlockList />} />
                                <Route path='manage/:identifier' element={<AdminBlockManage  />} />
                                <Route path='new' element={<FullAdminAuth />}>
                                    <Route path='' element={<AdminBlockNew />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path='/admin/unauthorized' element={<AdminUnauthorized />} />
                        <Route path="/admin/*" element={<Navigate to={{pathname: "/admin", search: window.location.search, hash: window.location.hash}} replace />} />
                        <Route path="*" element={<Navigate to={{pathname: "/", search: window.location.search, hash: window.location.hash}} replace />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Box>
    )
}
